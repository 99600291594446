export function useCloudStorage() {
    const webApp = window.Telegram.WebApp
    const getKeys = () => {
        webApp.CloudStorage.getKeys((e, value)=>{
            return value
        })
    }

    const removeAll = () => {
        webApp.CloudStorage.getKeys((e, value)=>{
            webApp.CloudStorage.removeItems(value)
        })
    }

    return {
        webApp,
        getKeys,
        removeAll
    }
}