import React, {useEffect, useState} from 'react';
import {useTelegram} from "../../../../hooks/useTelegram";
import classes from "./cart.module.css";
import {text} from "../../../translate";
import TelegramScreen from "../../../common/telegramScreen/telegramScreen";
import {get_course, get_item, server, tracking3} from "../../../../utils/api";
import {toRub} from "../../../../utils/money";

const Cart = () => {
    const {lang, webApp} = useTelegram()
    const [items, setItems] = useState([])
    const [course, setCourse] = useState(100)

    useEffect(()=>{
        const getCourse = async ()=>{
            const res = await get_course()
            setCourse(res+1)
            await getBasket()
        }
        getCourse()
    },[])

    async function getBasket(){
        webApp.CloudStorage.getKeys((e, value)=>{
            webApp.CloudStorage.getItems(value,async (e, value2)=>{
                const keys = Object.keys(value2)
                let basket = []
                for(let i = 0; i < keys.length; i++){
                    const res = await get_item(keys[i])
                    if(res)
                        basket.push({item:res, count:parseInt(value2[keys[i]])})
                }
                setItems(basket)
                await tracking3(
                    webApp.initDataUnsafe?.user.id,
                    basket
                )
            })
        })
    }

    function addItem(item){
        let res = [];
        items.forEach(i =>{
            if(i === item){
                res.push({item: i.item, count: parseInt(item.count)+1})
            }
            else{
                res.push(i)
            }
        })
        setItems(res)
        webApp.CloudStorage.setItem(item.item.id,parseInt(item.count)+1,()=>{})
    }

    function setItem(item, count){
        let countT = parseInt(count);
        if(countT < 0)
            countT = 0
        let res = [];
        items.forEach(i =>{
            if(i === item){
                res.push({item: i.item, count: countT})
            }
            else{
                res.push(i)
            }
        })
        setItems(res)
        if(count === 0) {
            allDeleteItem(item)
        }else{
            webApp.CloudStorage.setItem(item.item.id, count,()=>{})
        }
    }

    function deleteItem(item){
        let res = [];
        items.forEach(i =>{
            if(i === item){
                if(item.count > 1)
                    res.push({item: i.item, count: parseInt(item.count)-1})
            }
            else{
                res.push(i)
            }
        })
        setItems(res)
        if(item.count > 1){
            webApp.CloudStorage.setItem(item.item.id,parseInt(item.count)-1,()=>{})
        }else{
            webApp.CloudStorage.removeItem(item.item.id,()=>{})
        }
    }

    function allDeleteItem(item){
        let res = [];
        items.forEach(i =>{
            if(i !== item){
                res.push(i)
            }
        })
        setItems(res)
        webApp.CloudStorage.removeItem(item.item.id,()=>{})
    }

    return (
        <TelegramScreen className={classes._} showbackbutton={true}>
            {items.map(item =>
                <div className={classes.block}>
                    <div className={classes.imageBlock}>
                        <img className={classes.image} alt={''} src={server+item.item.img}/>
                    </div>
                    <div className={classes.blockInfo}>
                        {lang === 'en' ? <div className={classes.price}>{(item.item.price * item.count).toFixed(0)} USDT</div> :
                            <>
                                <div className={classes.price}>{toRub((item.item.price * course * item.count).toFixed(0))} руб</div>
                                <div className={classes.price2}>{(item.item.price * item.count).toFixed(0)} USDT</div>
                            </>
                        }
                        <div className={classes.name}>{item.item.firma} {item.item.name}</div>
                        <div className={classes.hashrate}>{text[lang].cart.text2} <span className={classes.hashrate2}>{item.item.hashrate}</span></div>
                        <div className={classes.buttonBlock}>
                            <div className={classes.delete} onClick={()=>allDeleteItem(item)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M10.6667 3.99992V3.46659C10.6667 2.71985 10.6667 2.34648 10.5213 2.06126C10.3935 1.81038 10.1895 1.60641 9.93865 1.47858C9.65344 1.33325 9.28007 1.33325 8.53333 1.33325H7.46667C6.71993 1.33325 6.34656 1.33325 6.06135 1.47858C5.81046 1.60641 5.60649 1.81038 5.47866 2.06126C5.33333 2.34648 5.33333 2.71985 5.33333 3.46659V3.99992M2 3.99992H14M12.6667 3.99992V11.4666C12.6667 12.5867 12.6667 13.1467 12.4487 13.5746C12.2569 13.9509 11.951 14.2569 11.5746 14.4486C11.1468 14.6666 10.5868 14.6666 9.46667 14.6666H6.53333C5.41323 14.6666 4.85318 14.6666 4.42535 14.4486C4.04903 14.2569 3.74307 13.9509 3.55132 13.5746C3.33333 13.1467 3.33333 12.5867 3.33333 11.4666V3.99992" stroke="var(--tg-theme-text-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div className={classes.countBlock}>
                                <div className={classes.minus} onClick={()=>deleteItem(item)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M3.33334 8H12.6667" stroke="var(--tg-theme-text-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <input className={classes.inputNumber}
                                       value={item.count}
                                       type={"number"}
                                       onChange={e=>{
                                           if(e.target.value.length < 6){
                                               setItem(item,e.target.value)}}
                                           }
                                />
                                <div className={classes.plus} onClick={()=>addItem(item)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M8 3.33325V12.6666M3.33333 7.99992H12.6667" stroke="var(--tg-theme-text-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </TelegramScreen>
    );
};

export default Cart;