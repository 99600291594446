import React, {useEffect} from 'react';
import classes from "./main.module.css";
import {text} from "../../translate";
import {useTelegram} from "../../../hooks/useTelegram";
import {useNavigate} from "react-router-dom";
import TelegramScreen from "../../common/telegramScreen/telegramScreen";
import gif1 from '../../../image/gif/cart-dark-bg.gif'
import gif2 from '../../../image/gif/cart-white-bg.gif'
import gif3 from '../../../image/gif/calc-dark-bg.gif'
import gif4 from '../../../image/gif/calc-white-bg.gif'
import {useCloudStorage} from "../../../hooks/useCloudStorage";

const Main = () => {
    const {lang, webApp} = useTelegram()
    const navigator = useNavigate()
    const {removeAll} = useCloudStorage()

    useEffect(()=>{
        removeAll()
    },[])

    return (
        <TelegramScreen className={classes._} showbackbutton={false}>
            <div className={classes.block+' '+(webApp.colorScheme === 'dark' && classes.block_d)} onClick={()=>navigator('shop')}>
                <div className={classes.text}>
                    {text[lang].main.text1}
                </div>
                {webApp.colorScheme === 'dark' && <img src={gif1} className={classes.gif1} alt={''}/>}
                {webApp.colorScheme === 'light' && <img src={gif2} className={classes.gif1} alt={''}/>}
                <div className={classes.block2}></div>
            </div>
            <div className={classes.block+' '+(webApp.colorScheme === 'dark' && classes.block_d)} onClick={()=>navigator('calc')}>
                <div className={classes.text}>
                    {text[lang].main.text2}
                </div>
                {webApp.colorScheme === 'dark' && <img src={gif3} className={classes.gif1} alt={''}/>}
                {webApp.colorScheme === 'light' && <img src={gif4} className={classes.gif1} alt={''}/>}
                <div className={classes.block2}></div>
            </div>
        </TelegramScreen>
    );
};

export default Main;