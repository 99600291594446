import React, {useEffect, useState} from 'react';
import classes from './models.module.css';
import {get_course, get_items, server} from "../../../../utils/api";
import {useTelegram} from "../../../../hooks/useTelegram";
import {text} from "../../../translate";
import TelegramScreen from "../../../common/telegramScreen/telegramScreen";
import {CSSTransition} from "react-transition-group";
import {toRub} from "../../../../utils/money";
import {getMonth} from "../../../../utils/month";

const Models = () => {
    const [items, setItems] = useState([])
    const [course, setCourse] = useState(100)
    const {webApp, lang} = useTelegram()
    const [dateText, setDateText] = useState('')
    const [openPopup, setOpenPopup] = useState(false)
    const [selectTag, setSelectTag] = useState({color:'#000', name_RU:'', name_EN:''})

    useEffect(()=>{
        const getCourse = async ()=>{
            const res = await get_course()
            setCourse(res+1)
             await getItems()
        }
        const getItems = async ()=>{
            let res = await get_items(window.location.search.split('=')[1])
            res = res.filter(item=>item.in_shop)
            let temp = []
            webApp.CloudStorage.getKeys((e, value)=>{
                webApp.CloudStorage.getItems(value,(e, value)=>{
                    res.forEach(item =>{
                        if(value[item.id]){
                            temp.push({item:item,count: parseInt(value[item.id])})
                        }
                        else{
                            temp.push({item:item,count: 0})
                        }
                    })
                    setItems(temp)
                })
            })
        }
        getCourse()
        getBasket()
    },[])

    function getBasket(){
        webApp.CloudStorage.getKeys((e, value)=>{
            webApp.CloudStorage.getItems(value,(e, value2)=>{
                if(value2.length === 0){
                    webApp.MainButton.hide()
                }else{
                    webApp.MainButton.show()
                }
            })
        })
    }

    function addItem(item){
        let res = [];
        items.forEach(i =>{
            if(i === item){
                res.push({item: i.item, count: parseInt(item.count)+1})
            }
            else{
                res.push(i)
            }
        })
        setItems(res)
        webApp.CloudStorage.setItem(item.item.id,parseInt(item.count)+1,()=>{})
        webApp.MainButton.show()
    }

    function setItem(item, count){
        let countT = parseInt(count);
        if(countT < 0)
            countT = 0
        let res = [];
        items.forEach(i =>{
            if(i === item){
                res.push({item: i.item, count: countT})
            }
            else{
                res.push(i)
            }
        })
        setItems(res)
        if(countT === 0) {
            webApp.CloudStorage.removeItem(item.item.id, () => {
                getBasket()
            })
        }else{
            webApp.MainButton.show()
            webApp.CloudStorage.setItem(item.item.id, count,()=>{})
        }
    }

    function deleteItem(item){
        let res = [];
        items.forEach(i =>{
            if(i === item){
                res.push({item: i.item, count: parseInt(item.count)-1})
            }
            else{
                res.push(i)
            }
        })
        setItems(res)
        if(item.count > 1){
            webApp.CloudStorage.setItem(item.item.id,parseInt(item.count)-1,()=>{
                getBasket()
            })
        }else{
            webApp.CloudStorage.removeItem(item.item.id,()=>{
                getBasket()
            })
        }
    }

    return (
        <TelegramScreen className={classes._} showbackbutton={true}>
            {items.map(item=>
                <div key={item.item.id} className={classes.block}>
                    <div className={classes.imageBlock}>
                        <img className={classes.image} src={server+item.item.img} alt={''}/>
                    </div>
                    <div className={classes.tags}>
                        {item.item.tags.map(tag =>
                            <div className={classes.tag}
                                 style={{background:tag.color, cursor: (tag.name_RU==='В пути' || tag.name_RU==='Предзаказ')?'pointer':'initial'}}
                                onClick={()=>{
                                    if(tag.name_RU==='В пути'){
                                        setSelectTag(tag)
                                        setDateText(item.item.date2)
                                        setOpenPopup(true)
                                        setTimeout(()=>{setOpenPopup(false)},2000)
                                    }
                                    if(tag.name_RU==='Предзаказ'){
                                        setSelectTag(tag)
                                        setDateText(item.item.date)
                                        setOpenPopup(true)
                                        setTimeout(()=>{setOpenPopup(false)},2000)
                                    }
                                }}
                            >
                                {lang === 'en' ? tag.name_EN : tag.name_RU}
                            </div>)}
                    </div>
                    <div className={classes.name}><div>{item.item.firma}</div><div>{item.item.name} {item.item.hashrate}</div></div>
                    {lang === 'en' ? <div className={classes.price}>{item.item.price} USDT</div> :
                        <>
                            <div className={classes.price}>{toRub((item.item.price * course).toFixed(0))} руб</div>
                            <div className={classes.price2}>{item.item.price} USDT</div>
                        </>
                    }
                    {item.count === 0 ?
                        <div className={classes.button} onClick={()=>addItem(item)}>
                            {text[lang].shop.text2}
                        </div>:
                        <div className={classes.button2}>
                            <div className={classes.buttonIcon} onClick={()=>deleteItem(item)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M3.75 8H13.0833" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <input className={classes.inputNumber}
                                   value={item.count}
                                   type={"number"}
                                   onChange={e=>{
                                       if(e.target.value.length < 6){
                                           setItem(item,e.target.value)}}
                                   }
                            />
                            <div className={classes.buttonIcon} onClick={()=>addItem(item)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M8.08335 3.33325V12.6666M3.41669 7.99992H12.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    }
                </div>
            )}
            <CSSTransition in={openPopup}
                           timeout={300}
                           classNames="block"
                           unmountOnExit
            >
                <div className={classes.popupFon}>
                    <div className={classes.popup}>
                        <div className={classes.tag} style={{background:selectTag.color}}>
                            {lang === 'en' ? selectTag.name_EN : selectTag.name_RU}
                        </div>
                        <div className={classes.popupBlock}>
                            <div className={classes.popupText1}>{text[lang].shop.text4}</div>
                            <div className={classes.popupText2}>{getMonth(dateText, lang)}</div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </TelegramScreen>
    );
};

export default Models;