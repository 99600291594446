export function getMonth(d, lang){
    if(d==='')
        return ''
    let dd = d.split('-')
    let res = parseInt(dd[2]).toString()+' '
    if(lang === 'en'){
        switch (parseInt(dd[1])){
            case 1: res += 'January'; break;
            case 2: res += 'February'; break;
            case 3: res += 'March'; break;
            case 4: res += 'April'; break;
            case 5: res += 'May'; break;
            case 6: res += 'June'; break;
            case 7: res += 'July'; break;
            case 8: res += 'August'; break;
            case 9: res += 'September'; break;
            case 10: res += 'October'; break;
            case 11: res += 'November'; break;
            case 12: res += 'December'; break;
        }
    }else{
        switch (parseInt(dd[1])){
            case 1: res += 'января'; break;
            case 2: res += 'февраля'; break;
            case 3: res += 'марта'; break;
            case 4: res += 'апреля'; break;
            case 5: res += 'мая'; break;
            case 6: res += 'июня'; break;
            case 7: res += 'июля'; break;
            case 8: res += 'августа'; break;
            case 9: res += 'сентября'; break;
            case 10: res += 'октября'; break;
            case 11: res += 'ноября'; break;
            case 12: res += 'декабря'; break;
        }
    }
    return res
}