import React, {useEffect, useState} from 'react';
import {useTelegram} from "../../../hooks/useTelegram";
import {text} from "../../translate";
import classes from "./shop.module.css";
import {get_models, server} from "../../../utils/api";
import TelegramScreen from "../../common/telegramScreen/telegramScreen";

const Shop = () => {
    const {lang, webApp} = useTelegram()
    const [models, setModels] = useState([])

    useEffect(()=>{
        const getModels = async ()=>{
            let res = await get_models()
            res = res.filter(item=>item.in_shop)
            setModels(res)
        }
        getModels()
    },[])

    return (
        <TelegramScreen className={classes._} showbackbutton={true}>
            <div className={classes.text1}>{text[lang].shop.text1}</div>
            <div className={classes.blockModels}>
                {models.map((item,number) =>
                    <div key={item.id} className={classes.block+' '+(number!==0 && classes.block_1)}
                        onClick={()=>window.location.href='/shop/models?id='+item.id}
                    >
                        <div className={classes.imageModelsBlock}>
                            {(webApp.colorScheme === 'dark' && item.img_dark) && <img alt='' className={classes.imageModels} src={server+item.img_dark}/>}
                            {(webApp.colorScheme === 'light' && item.img_light) && <img alt='' className={classes.imageModels} src={server+item.img_light}/>}
                        </div>
                        <div className={classes.block_text}>{item.name}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                            <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="#999999"/>
                        </svg>
                    </div>
                )}
            </div>
        </TelegramScreen>
    );
};

export default Shop;