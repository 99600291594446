import React, {useEffect, useRef, useState} from 'react';
import classes from "./calc.module.css";
import classes2 from '../shop/cart/cart.module.css'
import TelegramScreen from "../../common/telegramScreen/telegramScreen";
import {useTelegram} from "../../../hooks/useTelegram";
import {text} from "../../translate";
import {
    get_course,
    get_courseBTC,
    get_items,
    get_models,
    get_profit,
    get_stat,
    server,
    tracking2
} from "../../../utils/api";
import {useNavigate} from "react-router-dom";
import {toRub} from "../../../utils/money";
import Loading from "../../common/loading/loading";
import {CSSTransition} from "react-transition-group";

const Calc = ({step, setStep}) => {
    const navigate = useNavigate();
    const {lang, webApp} = useTelegram()
    const [loading, setLoading] = useState(false)
    const [valueEl, setValueEl] = useState('')
    const [models, setModels] = useState([])
    const [model, setModel] = useState({})
    const [items, setItems] = useState([])
    const [items2, setItems2] = useState([])
    const [item, setItem] = useState({})
    const [item2, setItem2] = useState({})
    const [count, setCount] = useState('')
    const [allItems, setAllItems] = useState([])
    const [days, setDays] = useState(1)
    const [energyFee, setEnergyFee] = useState(0)
    const [courseBTC, setCourseBTC] = useState(0)
    const [courseUSD, setCourseUSD] = useState(0)
    const [courseBTCRub, setCourseBTCRub] = useState(0)
    const [valueBTC, setValueBTC] = useState(0)
    const [profit, setProfit] = useState(0)
    const [profitCount, setProfitCount] = useState(0)
    const ref = useRef();
    const ref2 = useRef();

    useEffect(()=>{
        const getModels = async ()=>{
            setLoading(true)
            setModels(await get_models())
            setLoading(false)
            const c = await get_course()
            const btc = await get_courseBTC()
            const prof = await get_profit()
            setCourseBTCRub(btc)
            setCourseUSD(c)
            setProfitCount(prof)
            if(lang === 'ru')
                setCourseBTC(btc)
            else
                setCourseBTC(btc / c)
            setValueBTC(await get_stat())
        }
        getModels()
    },[])

    useEffect(()=>{
        const getItems = async ()=>{
            if(model.id){
                setLoading(true)
                let res = await get_items(model.id)
                res = res.filter(item=>item.in_calc)
                let temp = []
                let temp2 = []
                res.forEach(item=>{
                    if(temp.indexOf(item.name) === -1){
                        temp.push(item.name)
                        temp2.push(item)
                    }
                })
                setItems(temp2)
                setLoading(false)
            }
        }
        getItems()
    },[model])

    useEffect(()=>{
        const getItems = async ()=>{
            if(model.id){
                setLoading(true)
                let res = await get_items(model.id)
                res = res.filter(item=>item.in_calc)
                let temp = []
                res.forEach(i=>{
                    if(i.name === item.name){
                        temp.push(i)
                    }
                })
                setItems2(temp)
                setLoading(false)
            }
        }
        getItems()
    },[item])

    useEffect(()=>{
        setCount('')
        //if(step === 2){
        //    setItems2([])
        //}
    },[step])

    useEffect(()=>{
        let energyFeeT = 0;
        let prof = 0;
        allItems.forEach(item =>{
            energyFeeT += (parseFloat(valueEl.replace(',','.')) ? parseFloat(valueEl.replace(',','.')) : 0) * 24 * item.count * item.item.power / 1000
            if(item.item.type === 'BTC'){
                prof += item.item.hashrate.split(' ')[0] * item.count * valueBTC * Math.pow(10,12)
            } else {
                prof += item.item.hashrate.split(' ')[0] * profitCount[item.item.type] * courseUSD / courseBTCRub * item.count
            }
            if(item.item.type === 'kHeavyHash' && item.item.hashrate.split(' ')[1] === 'th'){
                prof = prof * 1000
            }
            if(item.item.type === 'Blake3' && item.item.hashrate.split(' ')[1] === 'gh'){
                prof = prof / 1000
            }
            /*if(item.item.type === 'Ethash'){
                prof += item.item.hashrate.split(' ')[0] * 556.3 / 2400 / courseBTCRub * item.count
            }
            if(item.item.type === 'Scrypt'){
                prof += item.item.hashrate.split(' ')[0] * 955 / 9500 / courseBTCRub * item.count
            }
            if(item.item.type === 'Eaglesong'){
                prof += item.item.hashrate.split(' ')[0] * 1176.54 / 63.5 / courseBTCRub * item.count
            }
            if(item.item.type === 'kHeavyHash'){
                prof += item.item.hashrate.split(' ')[0] * 1376.72 / 166 / courseBTCRub * item.count
            }
            if(item.item.type === 'X11'){
                prof += item.item.hashrate.split(' ')[0] * 1542 / 1779 / courseBTCRub * item.count
            }
            if(item.item.type === 'Handshake'){
                prof += item.item.hashrate.split(' ')[0] * profitCount['Handshake'] * courseUSD / courseBTCRub * item.count
            }*/
        })
        setProfit(prof)
        setEnergyFee(energyFeeT);

    },[allItems, valueEl])

    function addItem(){
        if(count > 0){
            let temp = []
            let flag = true
            allItems.forEach(i=>{
                if(i.item === item2){
                    temp.push({item:item2, count:i.count + count})
                    flag = false
                }else{
                    temp.push(i)
                }
            })
            if(flag)
                temp.push({item:item2, count:count})
            setAllItems(temp)
            setCount(0)
            setStep(0)
        }
    }

    function deleteItem(it){
        let temp = []
        allItems.forEach(i=>{
            if(it.item !== i.item)
                temp.push(i)
        })
        setAllItems(temp)
    }

    function plusItem(it){
        let temp = []
        allItems.forEach(i=>{
            if(i.item === it.item)
                temp.push({item:i.item, count:parseInt(i.count)+1})
            else
                temp.push(i)
        })
        setAllItems(temp)
    }

    function minusItem(it){
        let temp = []
        allItems.forEach(i=>{
            if(i.item === it.item){
                if(parseInt(i.count) > 1)
                    temp.push({item:i.item, count:parseInt(i.count)-1})
            }
            else
                temp.push(i)
        })
        setAllItems(temp)
    }

    function setCountItem(it, c){
        let temp = []
        allItems.forEach(i=>{
            if(i.item === it.item)
                temp.push({item:i.item, count:c})
            else
                temp.push(i)
        })
        setAllItems(temp)
    }

    function goShop(){
        setStep(0);
        let flag = true;
        allItems.forEach(i => {
            if(i.item.in_shop){
                webApp.CloudStorage.setItem(i.item.id, parseInt(i.count),()=>{})
                webApp.MainButton.show()
            }else{
                flag = false
            }
        })
        if(flag){
            navigate('/cart')
        }else{
            setStep(6)
        }
    }

    async function calcStart(){
        if(allItems.length > 0) {
            setStep(5)
            const res = await tracking2(
                webApp.initDataUnsafe?.user.id,
                allItems
                )
            console.log(res)
        }
    }

    return (
        <TelegramScreen showbackbutton={true}>
            <div className={classes.mainBlock}>
                {allItems.map(i =>
                    <div className={classes2.block}>
                        <div className={classes2.imageBlock}>
                            <img className={classes2.image} alt={''} src={server+i.item.img}/>
                        </div>
                        <div className={classes2.blockInfo}>
                            <div className={classes2.name}>{i.item.firma} {i.item.name}</div>
                            <div className={classes2.hashrate}>{text[lang].cart.text2} <span className={classes2.hashrate2}>{i.item.hashrate}</span></div>
                            <div className={classes2.buttonBlock}>
                                <div className={classes2.delete} onClick={()=>deleteItem(i)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M10.6667 3.99992V3.46659C10.6667 2.71985 10.6667 2.34648 10.5213 2.06126C10.3935 1.81038 10.1895 1.60641 9.93865 1.47858C9.65344 1.33325 9.28007 1.33325 8.53333 1.33325H7.46667C6.71993 1.33325 6.34656 1.33325 6.06135 1.47858C5.81046 1.60641 5.60649 1.81038 5.47866 2.06126C5.33333 2.34648 5.33333 2.71985 5.33333 3.46659V3.99992M2 3.99992H14M12.6667 3.99992V11.4666C12.6667 12.5867 12.6667 13.1467 12.4487 13.5746C12.2569 13.9509 11.951 14.2569 11.5746 14.4486C11.1468 14.6666 10.5868 14.6666 9.46667 14.6666H6.53333C5.41323 14.6666 4.85318 14.6666 4.42535 14.4486C4.04903 14.2569 3.74307 13.9509 3.55132 13.5746C3.33333 13.1467 3.33333 12.5867 3.33333 11.4666V3.99992" stroke="var(--tg-theme-text-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div className={classes2.countBlock}>
                                    <div className={classes2.minus} onClick={()=>minusItem(i)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M3.33334 8H12.6667" stroke="var(--tg-theme-text-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <input className={classes2.inputNumber} value={i.count} type={"number"} onChange={e=>setCountItem(i,e.target.value)} />
                                    <div className={classes2.plus} onClick={()=>plusItem(i)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8 3.33325V12.6666M3.33333 7.99992H12.6667" stroke="var(--tg-theme-text-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className={classes.text1} onClick={()=>setStep(1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M8.89823 0.256847L9 0.25C9.3797 0.25 9.69349 0.532154 9.74315 0.898229L9.75 1V8.25H17C17.3797 8.25 17.6935 8.53215 17.7432 8.89823L17.75 9C17.75 9.3797 17.4678 9.69349 17.1018 9.74315L17 9.75H9.75V17C9.75 17.3797 9.46785 17.6935 9.10177 17.7432L9 17.75C8.6203 17.75 8.30651 17.4678 8.25685 17.1018L8.25 17V9.75H1C0.620304 9.75 0.306509 9.46785 0.256847 9.10177L0.25 9C0.25 8.6203 0.532154 8.30651 0.898229 8.25685L1 8.25H8.25V1C8.25 0.620304 8.53215 0.306509 8.89823 0.256847L9 0.25L8.89823 0.256847Z" fill="var(--tg-theme-link-color)"/>
                    </svg>
                    {text[lang].calc.text1}
                </div>
                <div className={classes.blockEl} onClick={()=>ref.current.focus()}>
                    <div className={classes.text2}>{text[lang].calc.text2}</div>
                    <div className={classes.blockEl2}>
                        <input type={"text"}
                               ref={ref}
                               className={classes.inputEl}
                               value={valueEl}
                               pattern="\\d*"
                               inputmode="decimal"
                               placeholder={'0'}
                               onChange={(e)=> {
                                   if(e.target.value.length === 0){
                                       setValueEl('')
                                   }else{
                                       if (e.target.value.length < 6) {
                                           let last = e.target.value[e.target.value.length - 1]
                                           const alf = '1234567890,.'
                                           if(e.target.value === '.' || e.target.value === ','){
                                               setValueEl('0,')
                                           }else{
                                               if (alf.indexOf(last) !== -1) {
                                                   if((last === '.' || last === ',') && (e.target.value.slice(0,-1).indexOf('.') !== -1 || e.target.value.slice(0,-1).indexOf(',') !== -1)){

                                                   }else{
                                                       if (e.target.value.indexOf(',') !== -1 || e.target.value.indexOf('.') !== -1)
                                                           ref.current.style.width = e.target.value.length * 18 + 'px';
                                                       else
                                                           ref.current.style.width = e.target.value.length * 18 + 8 + 'px';
                                                       setValueEl(e.target.value)
                                                   }
                                               }
                                           }
                                       }
                                   }

                                   }}
                        />
                        <div className={classes.text3}>{text[lang].calc.text3}</div>
                    </div>
                </div>
            </div>
            <div className={allItems.length > 0 ? classes.footer : classes.footer2} onClick={calcStart}>
                {text[lang].payment.text14}
            </div>
            <CSSTransition in={step === 1}
                           timeout={300}
                           classNames="block2"
                           unmountOnExit
            >
                <div className={classes.popup}>
                    <div className={classes.text1_1}>{text[lang].shop.text1}</div>
                    <div className={classes.blockModels}>
                        {models.map((item,number) =>
                            <div key={item.id} className={classes.block+' '+(number!==0 && classes.block_1)}
                                 onClick={()=>{setModel(item); setStep(2)}}
                            >
                                <div className={classes.imageModelsBlock}>
                                    {(webApp.colorScheme === 'dark' && item.img_dark) && <img alt='' className={classes.imageModels} src={server+item.img_dark}/>}
                                    {(webApp.colorScheme === 'light' && item.img_light) && <img alt='' className={classes.imageModels} src={server+item.img_light}/>}
                                </div>
                                <div className={classes.block_text}>{item.name}</div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                                    <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="#999999"/>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={step === 2}
                           timeout={30}
                           classNames="block2"
                           unmountOnExit
            >
                <div className={classes.popup}>
                    <div className={classes.text1_1}>{text[lang].calc.text4}<span className={classes.text4}>{model.name}</span>{text[lang].calc.text5}</div>
                    <div className={classes.blockModels}>
                        {items.map((item,number) =>
                            <div key={item.id} className={classes.block+' '+(number!==0 && classes.block_1)}
                                 onClick={()=>{setItem(item); setStep(3)}}
                            >
                                <div className={classes.imageModelsBlock}>
                                    <img alt='' className={classes.imageModels} src={server+item.img}/>
                                </div>
                                <div className={classes.block_text}>{item.name}</div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                                    <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="#999999"/>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={step === 3}
                           timeout={30}
                           classNames="block2"
                           unmountOnExit
            >
                <div className={classes.popup}>
                    <div className={classes.text1_1}>{text[lang].calc.text6}<div className={classes.text4}>{item.firma} {item.name}</div></div>
                    <div className={classes.blockModels}>
                        {items2.map((i,number) =>
                            <div style={{height:'46px'}} key={i.id} className={classes.block+' '+(number!==0 && classes.block_1)}
                                 onClick={()=>{setItem2(i); setStep(4)}}
                            >
                                <div className={classes.block_text}>{i.hashrate}</div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                                    <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="#999999"/>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={step === 4}
                           timeout={30}
                           classNames="block2"
                           unmountOnExit
            >
                <div className={classes.popup2}>
                    <div className={classes.mainBlock} style={{padding: '0 16px'}}>
                        <div className={classes.text1_1} style={{marginTop:'40px'}}>{text[lang].calc.text7}</div>
                        <div className={classes.blockModels} onClick={()=>ref2.current.focus()}>
                            <div className={classes.inputCountBlock}>
                                <input className={classes.inputCount}
                                       ref={ref2}
                                       type={"number"}
                                       value={count}
                                       placeholder={0}
                                       pattern="\\d*"
                                       inputmode="decimal"
                                       onChange={(e)=>{
                                           if(e.target.value.length < 6){
                                               ref2.current.style.width = e.target.value.length * 22 + 8 + 'px';
                                               setCount(parseInt(e.target.value))
                                           }
                                       }}/>
                                <div className={classes.inputCountNumber}>{text[lang].calc.text8}</div>
                            </div>
                        </div>
                    </div>
                    <div className={count > 0 ? classes.footer : classes.footer2} onClick={addItem}>
                        {text[lang].payment.text14}
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={step === 5}
                           timeout={300}
                           classNames="block"
                           unmountOnExit
            >
                <div className={classes.popup2}>
                    <div className={classes.mainBlock2}>
                        <div className={classes.text5} style={{marginTop:'24px'}}>{text[lang].calc.text9}</div>
                        <div className={classes.text6}>{toRub(((profit - energyFee / courseBTC) * courseBTC * days).toFixed(2))}
                            {days===1 && <span className={classes.text7}>{text[lang].calc.text10}</span>}
                            {days===7 && <span className={classes.text7}>{text[lang].calc.text10_1}</span>}
                            {days===30.5 && <span className={classes.text7}>{text[lang].calc.text10_2}</span>}
                        </div>
                        <div className={classes.blockProfit}>
                            <div className={classes.blockProfitHeader}>
                                <div className={classes.blockProfitHeaderText+' '+(days===1 && classes.blockProfitHeaderText2)} onClick={()=>setDays(1)}>{text[lang].calc.text11}</div>
                                <div className={classes.blockProfitHeaderText+' '+(days===7 && classes.blockProfitHeaderText2)} onClick={()=>setDays(7)}>{text[lang].calc.text12}</div>
                                <div className={classes.blockProfitHeaderText+' '+(days===30.5 && classes.blockProfitHeaderText2)} onClick={()=>setDays(30.5)}>{text[lang].calc.text13}</div>
                            </div>
                            <div className={classes.blockProfitInfoBlock}>
                                <div className={classes.blockProfitInfo}>
                                    <div className={classes.blockProfitText1}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.00002 1.33337C3.93066 1.33337 2.93335 1.48989 2.17947 1.76404C2.15608 1.77254 2.13279 1.78121 2.1096 1.79004C1.76186 1.92254 1.43874 2.09233 1.18688 2.30886C0.920401 2.53796 0.666687 2.88188 0.666687 3.33337V4.33337C0.666687 4.78487 0.920401 5.12879 1.18688 5.35789C1.45553 5.58885 1.80526 5.76664 2.17947 5.90271C2.93335 6.17685 3.93066 6.33337 5.00002 6.33337C6.06938 6.33337 7.06669 6.17685 7.82057 5.90271C8.19478 5.76664 8.54451 5.58885 8.81316 5.35789C9.07964 5.12879 9.33335 4.78487 9.33335 4.33337V3.33337C9.33335 2.88188 9.07964 2.53796 8.81316 2.30886C8.54451 2.07789 8.19478 1.90011 7.82057 1.76404C7.06669 1.48989 6.06938 1.33337 5.00002 1.33337Z" fill="#E35443"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M9.33335 5.46828C8.90964 5.54052 8.51975 5.6403 8.17947 5.76404C7.80526 5.90011 7.45553 6.07789 7.18688 6.30886C6.9204 6.53796 6.66669 6.88188 6.66669 7.33337C6.66669 7.40101 6.67238 7.46623 6.68296 7.52901C6.16359 7.6186 5.59288 7.66671 5.00002 7.66671C3.93066 7.66671 2.93335 7.51019 2.17947 7.23605C1.79225 7.09524 1.43566 6.91409 1.15892 6.66671C1.05509 6.5739 0.971377 6.47846 0.904071 6.38789C0.87903 6.3542 0.839987 6.33337 0.798007 6.33337C0.7261 6.33337 0.668443 6.39276 0.669756 6.46466C0.677068 6.86498 0.666687 7.26657 0.666687 7.66671C0.666687 8.1182 0.920401 8.46212 1.18688 8.69122C1.45553 8.92219 1.80526 9.09997 2.17947 9.23605C2.93335 9.51019 3.93066 9.66671 5.00002 9.66671C5.56989 9.66671 6.1193 9.62226 6.62231 9.53924C6.63799 9.53666 6.64583 9.53536 6.65178 9.53141C6.65701 9.52795 6.66119 9.52302 6.66376 9.51729C6.66669 9.51078 6.66669 9.50278 6.66669 9.48679V9.23445C6.66669 9.19704 6.69702 9.16671 6.73443 9.16671C6.76085 9.16671 6.78471 9.18213 6.79659 9.20572C6.89542 9.402 7.03919 9.56425 7.18688 9.69123C7.45553 9.92219 7.80526 10.1 8.17947 10.236C8.93335 10.5102 9.93066 10.6667 11 10.6667C12.0694 10.6667 13.0667 10.5102 13.8206 10.236C14.1948 10.1 14.5445 9.92219 14.8132 9.69123C15.0796 9.46212 15.3334 9.1182 15.3334 8.66671V7.33337C15.3334 6.88188 15.0796 6.53796 14.8132 6.30886C14.5445 6.07789 14.1948 5.90011 13.8206 5.76404C13.0667 5.48989 12.0694 5.33337 11 5.33337C10.4133 5.33337 9.84836 5.38049 9.33335 5.46828Z" fill="#E35443"/>
                                            <path d="M15.2018 10.6667C15.1571 10.6667 15.1159 10.6896 15.0898 10.7258C15.0073 10.8407 14.9111 10.9404 14.8132 11.0246C14.5445 11.2555 14.1948 11.4333 13.8206 11.5694C13.0667 11.8435 12.0694 12 11 12C9.93066 12 8.93335 11.8435 8.17947 11.5694C7.80526 11.4333 7.45553 11.2555 7.18688 11.0246C7.08896 10.9404 6.99276 10.8407 6.91024 10.7258C6.88419 10.6896 6.84293 10.6667 6.79829 10.6667C6.72561 10.6667 6.66669 10.7256 6.66669 10.7983V10.8651C6.15168 10.9529 5.58669 11 5.00002 11C3.93066 11 2.93335 10.8435 2.17947 10.5694C1.80526 10.4333 1.45553 10.2555 1.18688 10.0246C1.08896 9.94037 0.992759 9.84068 0.910239 9.72584C0.884189 9.68959 0.842927 9.66671 0.798286 9.66671C0.725606 9.66671 0.666687 9.72563 0.666687 9.79831V11.3334C0.666687 11.7849 0.920401 12.1288 1.18688 12.3579C1.45553 12.5889 1.80526 12.7666 2.17947 12.9027C2.93335 13.1769 3.93066 13.3334 5.00002 13.3334C5.63289 13.3334 6.23868 13.2786 6.78295 13.1778C6.8819 13.3867 7.03224 13.5583 7.18688 13.6912C7.45553 13.9222 7.80526 14.1 8.17947 14.236C8.93335 14.5102 9.93066 14.6667 11 14.6667C12.0694 14.6667 13.0667 14.5102 13.8206 14.236C14.1948 14.1 14.5445 13.9222 14.8132 13.6912C15.0796 13.4621 15.3334 13.1182 15.3334 12.6667V10.7983C15.3334 10.7256 15.2744 10.6667 15.2018 10.6667Z" fill="#E35443"/>
                                        </svg>
                                        {text[lang].calc.text14}
                                    </div>
                                    <div>
                                        <div className={classes.blockProfitText2}>{(profit * days).toFixed(8)} BTC</div>
                                        <div className={classes.blockProfitText3}>{toRub((profit * days * courseBTC).toFixed(2))} {lang === 'ru' ? <>RUB</> : <>USD</>}</div>
                                    </div>
                                </div>
                                <div className={classes.blockProfitInfo}>
                                    <div className={classes.blockProfitText1}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M9.32818 1.41602C9.36488 1.12235 9.2036 0.839641 8.93212 0.721796C8.66064 0.60395 8.34397 0.679184 8.15451 0.90654L2.20153 8.05011C2.09866 8.17351 1.99445 8.29851 1.91859 8.40835C1.84684 8.51223 1.71583 8.71648 1.71178 8.98492C1.70715 9.29223 1.84409 9.58461 2.08314 9.77778C2.29195 9.94652 2.53273 9.97664 2.65847 9.98802C2.79141 10.0001 2.95414 10 3.1148 10L7.2448 10L6.6718 14.584C6.6351 14.8776 6.79638 15.1604 7.06786 15.2782C7.33934 15.396 7.65601 15.3208 7.84547 15.0935L13.7984 7.94989C13.9013 7.82648 14.0055 7.70149 14.0814 7.59164C14.1531 7.48776 14.2841 7.28351 14.2882 7.01507C14.2928 6.70776 14.1559 6.41539 13.9168 6.22221C13.708 6.05347 13.4673 6.02336 13.3415 6.01197C13.2086 5.99993 13.0458 5.99996 12.8852 5.99999L8.75518 6L9.32818 1.41602Z" fill="#377DD6"/>
                                        </svg>
                                        {text[lang].calc.text15}
                                    </div>
                                    <div>
                                        <div className={classes.blockProfitText2}>{(energyFee * days / courseBTC).toFixed(8)} BTC</div>
                                        <div className={classes.blockProfitText3}>{toRub((energyFee * days).toFixed(2))} {lang === 'ru' ? <>RUB</> : <>USD</>}</div>
                                    </div>
                                </div>
                                <div className={classes.blockProfitInfo}>
                                    <div className={classes.blockProfitText1}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M11 1.33329C9.34317 1.33329 8.00002 2.67644 8.00002 4.33329C8.00002 5.99015 9.34317 7.33329 11 7.33329C12.6569 7.33329 14 5.99015 14 4.33329C14 2.67644 12.6569 1.33329 11 1.33329Z" fill="#57976A"/>
                                            <path d="M8.02122 1.18613C7.18705 1.97595 6.66669 3.09385 6.66669 4.33329C6.66669 5.13135 6.88242 5.87903 7.25876 6.52118C6.96729 6.6156 6.65628 6.66663 6.33335 6.66663C4.6765 6.66663 3.33335 5.32348 3.33335 3.66663C3.33335 2.00977 4.6765 0.666626 6.33335 0.666626C6.95924 0.666626 7.54036 0.858292 8.02122 1.18613Z" fill="#57976A"/>
                                            <path d="M11.806 10.5955C11.806 11.123 11.457 11.5692 10.976 11.7183L11.2853 11.704C11.6233 11.704 11.9519 11.5977 12.222 11.4003L14.1079 10.0243C14.5786 9.68112 15.2375 9.72668 15.6535 10.1314C16.1156 10.5809 16.1156 11.3091 15.6535 11.7579L14.0353 13.332C13.5998 13.7557 13.044 14.045 12.4398 14.1627L10.1527 14.6077C9.69914 14.6958 9.23156 14.6851 8.78272 14.5758L6.71415 14.0731C6.4667 14.0124 6.21301 13.982 5.95775 13.982C5.68675 13.982 5.55126 13.982 5.44254 13.9432C5.25424 13.876 5.10606 13.7278 5.03883 13.5395C5.00002 13.4308 5.00002 13.2953 5.00002 13.0243V10.7382C5.00002 10.5476 5.00002 10.4523 5.02449 10.3645C5.04616 10.2868 5.08179 10.2136 5.12962 10.1485C5.18363 10.0751 5.2586 10.0163 5.40856 9.89874L6.19896 9.27905C6.19896 9.27905 7.50879 8.66662 8.2324 8.66662C8.53215 8.66662 8.83034 8.7031 9.1215 8.77529L10.9106 9.22006C11.4367 9.35046 11.806 9.82084 11.806 10.3603V10.5955Z" fill="#57976A"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M2.31571 8.66662C2.32159 8.66662 2.32747 8.66662 2.33336 8.66662C2.33925 8.66662 2.34513 8.66662 2.35101 8.66662C2.49104 8.6666 2.62745 8.66659 2.74351 8.67451C2.87042 8.68317 3.02091 8.70348 3.17693 8.76811C3.50364 8.90344 3.7632 9.163 3.89853 9.48971C3.96316 9.64574 3.98347 9.79622 3.99213 9.92313C4.00005 10.0392 4.00004 10.1756 4.00002 10.3156C4.00002 10.3215 4.00002 10.3274 4.00002 10.3333L4.00002 13.6843C4.00004 13.8243 4.00005 13.9607 3.99213 14.0768C3.98347 14.2037 3.96316 14.3542 3.89853 14.5102C3.7632 14.8369 3.50364 15.0965 3.17693 15.2318C3.0209 15.2964 2.87042 15.3167 2.74351 15.3254C2.62745 15.3333 2.49104 15.3333 2.35101 15.3333H2.3157C2.17567 15.3333 2.03926 15.3333 1.9232 15.3254C1.79629 15.3167 1.64581 15.2964 1.48978 15.2318C1.16307 15.0965 0.903508 14.8369 0.768183 14.5102C0.703554 14.3542 0.683238 14.2037 0.674579 14.0768C0.666661 13.9607 0.666674 13.8243 0.666688 13.6843L0.666689 10.3333C0.666689 10.3274 0.666689 10.3215 0.666688 10.3156C0.666674 10.1756 0.666661 10.0392 0.674579 9.92313C0.683238 9.79622 0.703554 9.64574 0.768183 9.48971C0.903509 9.163 1.16307 8.90344 1.48978 8.76811C1.64581 8.70348 1.79629 8.68317 1.92321 8.67451C2.03926 8.66659 2.17568 8.6666 2.31571 8.66662Z" fill="#57976A"/>
                                        </svg>
                                        {text[lang].calc.text16}
                                    </div>
                                    <div>
                                        <div className={classes.blockProfitText2}>{(profit * days - energyFee * days / courseBTC).toFixed(8)} BTC</div>
                                        <div className={classes.blockProfitText3}>{toRub(((profit * days - energyFee * days / courseBTC) * courseBTC).toFixed(2))} {lang === 'ru' ? <>RUB</> : <>USD</>}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.blockProfitInfoBlock}>
                                <div className={classes.blockProfitInfo}>
                                    <div className={classes.blockProfitText1}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <g clipPath="url(#clip0_1438_3111)">
                                                <path d="M15.7589 9.93524C14.6904 14.221 10.3496 16.8292 6.06335 15.7605C1.77889 14.692 -0.829339 10.351 0.239588 6.06558C1.30758 1.77935 5.64835 -0.82907 9.93329 0.239387C14.2192 1.30784 16.8273 5.64936 15.7587 9.93532L15.7588 9.93524H15.7589Z" fill="#F7931A"/>
                                                <path d="M11.5268 6.86022C11.686 5.7956 10.8755 5.22332 9.76712 4.84156L10.1267 3.39943L9.24881 3.18068L8.8988 4.58484C8.66801 4.52728 8.431 4.47305 8.19545 4.41928L8.548 3.00585L7.67068 2.78711L7.31093 4.22877C7.11995 4.18529 6.93237 4.14231 6.75038 4.09703L6.7514 4.09249L5.5408 3.79019L5.30728 4.7278C5.30728 4.7278 5.95858 4.8771 5.94486 4.88629C6.30034 4.97501 6.36463 5.21034 6.35396 5.39686L5.94439 7.03978C5.96887 7.046 6.00062 7.055 6.03565 7.06907C6.00637 7.0618 5.9752 7.05386 5.94286 7.04612L5.36879 9.34762C5.32534 9.45562 5.21507 9.61771 4.96653 9.55616C4.97533 9.56891 4.32848 9.39693 4.32848 9.39693L3.89264 10.4018L5.03504 10.6866C5.24757 10.7399 5.45583 10.7957 5.66093 10.8481L5.29766 12.3068L6.17451 12.5255L6.53426 11.0823C6.77381 11.1473 7.00628 11.2073 7.23386 11.2639L6.87532 12.7003L7.75323 12.919L8.11646 11.4631C9.61341 11.7464 10.739 11.6322 11.2128 10.2782C11.5945 9.18804 11.1938 8.55926 10.4062 8.14922C10.9798 8.01694 11.4119 7.63967 11.5271 6.86034L11.5268 6.86014L11.5268 6.86022ZM9.52105 9.67276C9.24975 10.7629 7.41432 10.1736 6.81925 10.0258L7.30131 8.09334C7.89635 8.2419 9.80458 8.53588 9.52109 9.67276H9.52105ZM9.79254 6.84442C9.54506 7.83601 8.01741 7.33224 7.52185 7.20871L7.95891 5.45606C8.45447 5.57959 10.0503 5.81014 9.79262 6.84442H9.79254Z" fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1438_3111">
                                                    <rect width="15.9982" height="16" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        {text[lang].calc.text20}
                                    </div>
                                    <div>
                                        <div className={classes.blockProfitText2}>{toRub((courseBTCRub / courseUSD).toFixed(2))} USD</div>
                                        {lang === 'ru' && <div className={classes.blockProfitText3}>{toRub(courseBTCRub.toFixed(2))} RUB</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.blockModels+' '+classes.blockModels2}>
                            {allItems.map((item,number) =>
                                <div key={item.item.id} className={classes.block+' '+(number!==0 && classes.block_1)} style={{cursor:'initial'}}>
                                    <div className={classes.imageModelsBlock}>
                                        <img alt='' className={classes.imageModels} src={server+item.item.img}/>
                                    </div>
                                    <div className={classes.block_text}>{item.item.firma} {item.item.name} {item.item.hashrate}</div>
                                    <div className={classes.block_count}>{item.count} {text[lang].calc.text17}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.footer} onClick={goShop}>
                        {text[lang].calc.text18}
                    </div>
                </div>
            </CSSTransition>
            {step === 6 &&
                <div className={classes.popup2}>
                    <div className={classes.mainBlock2}>
                        <div className={classes.text8}>{text[lang].calc.text19}</div>
                    </div>
                    <div className={classes.footer} onClick={()=>{setStep(0); navigate('/shop')}}>
                        {text[lang].calc.text18}
                    </div>
                </div>
            }
            <Loading isOpen={loading}/>
        </TelegramScreen>
    );
};

export default Calc;