import './App.css';
import {useCallback, useEffect, useState} from "react";
import Main from "./components/pages/main/main";
import {Route, Routes, useNavigate} from "react-router-dom";
import Shop from "./components/pages/shop/shop";
import {useTelegram} from "./hooks/useTelegram";
import Models from "./components/pages/shop/models/models";
import {text} from "./components/translate";
import Cart from "./components/pages/shop/cart/cart";
import Payment from "./components/pages/shop/payment/payment";
import Calc from "./components/pages/calc/calc";
import Invoice from "./components/pages/invoice/invoice";
import {tracking1, tracking4} from "./utils/api";

function App() {
  const {webApp, lang} = useTelegram()
  const navigate = useNavigate();
  const [openPaymentMethod, setOpenPaymentMethod] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState(0)
  const [deliveryMethod, setDeliveryMethod] = useState(0)
  const [deliveryAddress, setDeliveryAddress] = useState('')
  const [infoPhone, setInfoPhone] = useState('')
  const [infoName, setInfoName] = useState('')
  const [infoComment, setInfoComment] = useState('')
  const [timer, setTimer] = useState()

  const [step, setStep] = useState(0)

  useEffect(()=>{
    if(window.location.pathname === '/payment'){
      if(openPaymentMethod > 0)
        setOpenPaymentMethod(0)
      else{
        navigate(-1)
        webApp.MainButton.show()
      }
    }
    if(window.location.pathname === '/calc'){
      if(step === 0){
        navigate(-1)
      }
      if(step === 5){
        setStep(0)
      }
      if(step > 0 && step < 5){
        setStep(step-1)
      }
      if(step === 6){
        setStep(5)
      }
    }
  },[timer])

  useEffect(()=>{
    const track = async ()=>{
      const res = await tracking4(
          webApp.initDataUnsafe?.user.id,
          paymentMethod
      )
      console.log(res)
    }
    if(paymentMethod > 0){
      track()
    }
  },[paymentMethod])


  const onBackClick = useCallback(() => {
    if(window.location.pathname === '/calc'){
      setTimer(new Date)
      return
    }
    if(window.location.pathname === '/cart'){
      webApp.MainButton.setText(text[lang].shop.text3)
    }
    if(window.location.pathname === '/payment'){
      setTimer(new Date)
      return
      //webApp.MainButton.show()
    }
    if(window.location.pathname === '/shop/models'){
      webApp.MainButton.hide()
    }
    navigate(-1)
  },[navigate])

  const onMainClick = useCallback(() => {
    if(window.location.pathname === '/shop/models'){
      navigate('/cart')
      webApp.MainButton.setText(text[lang].cart.text3)
      return;
    }
    if(window.location.pathname === '/cart'){
      navigate('/payment')
      webApp.MainButton.hide()
      return;
    }
  }, [navigate])

  useEffect(() => {
    webApp.ready()
    webApp.enableClosingConfirmation()
    webApp.expand()
    webApp.BackButton.onClick(onBackClick)
    webApp.MainButton.setText(text[lang].shop.text3)
    webApp.MainButton.onClick(onMainClick)
    const track = async ()=>{
       const res = await tracking1(
           webApp.initDataUnsafe?.user.first_name+' '+webApp.initDataUnsafe?.user.last_name,
           webApp.initDataUnsafe?.user.username,
           webApp.initDataUnsafe?.user.id
       )
      console.log(res)
    }
    track()
    return () => {
      webApp.BackButton.offClick(onBackClick)
      webApp.MainButton.offClick(onMainClick)
    };
  }, [webApp])

  return (
      <div className="App">
        <Routes>
          <Route index element={<Main/>}/>
          <Route path='/calc' element={<Calc step={step} setStep={setStep}/>}/>
          <Route path='/shop' element={<Shop/>}/>
          <Route path='/shop/models' element={<Models/>}/>
          <Route path='/cart' element={<Cart/>}/>
          <Route path='/payment' element={<Payment
              openPaymentMethod={openPaymentMethod}
              setOpenPaymentMethod={setOpenPaymentMethod}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              deliveryMethod={deliveryMethod}
              setDeliveryMethod={setDeliveryMethod}
              deliveryAddress={deliveryAddress}
              setDeliveryAddress={setDeliveryAddress}
              infoPhone={infoPhone}
              setInfoPhone={setInfoPhone}
              infoName={infoName}
              setInfoName={setInfoName}
              infoComment={infoComment}
              setInfoComment={setInfoComment}
          />}/>
          <Route path='/account' element={<Invoice/>}/>
        </Routes>
      </div>
  )
}
export default App;
